// .template-formulaire-depot-images{
// 	padding-top: $p32;
// 	padding-bottom: $p64;
// 	position: relative;
// 	// overflow: hidden;

// 	.main-section_form{
// 		padding-top: $p64;
// 	}

// 	.content{
// 		display: flex;
// 		justify-content: center;
// 	}

// 	.block-form-holder{
// 		// background: inherit;
// 		width: 100% !important;
// 		max-width: 600px;
// 		margin-left: auto;
// 		margin-right: auto;
// 		padding: $p48;
// 		border-radius: 10px;

// 		@include break(tablet){
// 			padding: $p40;
// 		}

// 		@include break(mobile){
// 			padding: $p24 $p16;
// 		}

// 		h2{
// 			margin-bottom: $p64;
// 			// max-width: 370px;

// 			@include break(tablet){
// 				max-width: none;
// 				margin-bottom: $p48;
// 			}

// 			@include break(mobile){
// 				margin-bottom: $p32;
// 			}
// 		}
// 	}

// 	.block-form-holder{
// 		@include break(tablet){
// 			width: 100%;
// 			order: 2;
// 		}
// 	}

// 	.submit-info-form{
// 		.form-item{
// 			&.btn-holder{
// 				width: 100%;
// 			}
// 		}
// 	}
// }




.add-links-box .wpcf7-field-group-remove:before{
	background-image: url(../img/icon_trash_green.png);
}









