.oxo-view.template-home {

	.sec1 {
		display: flex;
		// height: 90vh;
		max-height: 100vh;
		min-height: 750px;

		@include break(tablet){
			flex-direction: column;
			min-height: 0;
			height: auto;
			max-height: unset;
		}

		.half-left{
			@include break(tablet){
				width: 100%;
				padding: 80px 30px;
			}

			.content{
				max-width: 650px;
				margin: auto;
				padding-top: 40px;
				padding-bottom: 40px;

				@include break(tablet){
					padding-top: 24px;
					padding-bottom: 24px;
				}
			}
		}

		.half-right{
			// width: 100%;
			flex: 1;

			@include break(tablet){
				width: 100%;
				
			}

			@include break(v-mobile){
				display: none;
			}
		}

		.content {
			text-align: center;
			padding-right: $p64;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			height: 100%;

			@include break(container) {
				padding-right: 5vw;
			}

			@include break(tablet){
				padding-right: 0;
			}

			h1 {
				padding-bottom: $p40;
			}

			.sub_title {
				width: 100%;
				display: flex;
				align-items: center;
				color: $blue;
				padding-top: $p48;
				padding-bottom: $p16;
				.line {
					width: 100%;
					background-color: $blue;
					height: 1px;
				}
				p {
					text-transform: uppercase;
					flex-shrink: 0;
					padding-left: $p16;
					padding-right: $p16;
				}
			}

		}

		.wrapper {
			height: 100%;
			width: 100%;
			position: relative;
			margin-left: $p64;
			overflow: hidden;
			@include break(container) {
				margin-left: 5vw;
			}

			@include break(tablet){
				margin-left: 0;
				min-height: 400px;
			}

			.bg {
				width: 100%;
				max-width: 550px;
				@include absolute-center(center);
			}
			.slide {
				position: absolute;
				left: 5%;
				right: 5%;
				top: 0%;
				bottom: 0;
				opacity: 0;

				img {
					width: 70%;
					margin-left: auto;
					margin-right: auto;
					@include absolute-center(center);

					@include break(tablet){
						width: 500px;
					}
				}
				
			}
			.logo_concours {
				position: absolute;
				left: 53%;
				top: 14%;
				width: 50%;
				min-width: 200px;
				max-width: 350px;

				@include break(tablet){
						left: 50%;
						width: 200px;
						// transform: translateX(200px);
					}
			}
		}

	}

	.sec2 {
		.container {
			padding-top: $p128;
			padding-bottom: $p128;
			display: flex;
			align-items: center;


			@include break(tablet){
				display: block;
				padding: $p80 0;
			}

			@include break(mobile){
				padding: $p64 0;
			}

			@include break(v-mobile){
				padding: $p40 0;
			}

			> div {
				width: 50%;

				@include break(tablet){
					width: 100%;
				}
			}
		}
		.img {
			height: 100%;
			position: relative;
			margin-right: $p64;

			@include break(tablet){
				max-width: 300px;
				height: auto;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: $p40;
			}

			.bg {
				width: 100%;
				// animation: rotate 100s linear infinite;
			}
			.logo_adaq {
				width: 27%;
				@include absolute-center(center);
			}
		}

		.content {
			text-align: center;
			padding-left: $p64;
			padding-right: $p64;

			@include break(tablet){
				width: 100%;
			}

			@include break(mobile){
				padding: 0;
			}


			p {
				padding-top: $p40;
			}
			a {
				margin-top: $p40;
			}
		}
	}




// À Faire
	.sec3 {
		padding-top: 100px;
		padding-bottom: $p24;

		h2{
			margin-bottom: 100px;
			text-align: center;
		}
	}


	.sec4 {
		.container {
			padding-top: $p128;
			padding-bottom: $p128;
			display: flex;
			justify-content: space-between;

			@include break(small-screen){
				display: block;
			}

			@include break(tablet){
				padding-bottom: $p64;
			}

			@include break(mobile){
				padding-bottom: 0;
			}

			aside {
				width: 50%;
				padding-right: $p128;

				@include break(small-screen){
					width: 100%;
					padding-right: 0;
				}

				.content {
					position: sticky;
					top: $p128;

					@include break(small-screen){
						position: static;
						margin-bottom: $p64;
						text-align: center;
					}

					p {
						padding-top: $p40;
					}
					a {
						margin-top: $p40;
					}
				}
			}

			.card_wrap {
				width: 50%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				min-width: 600px;

				@include break(small-screen){
					width: 100%;
					flex-wrap: nowrap;
					min-width: 0;
				}

				@include break(tablet){
					justify-content: center;
				}

				.card {
					transition: opacity 0.4s;
					
					&.active{
						opacity: 1;
					}

					@include break(tablet){
						margin-left: 20px;
						margin-right: 20px;
					}

				} //.card
			}
		}

		.modal iframe {
			display: none;

			&.active {
				display: block;
			}
		}
	}


}



// Anime
@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.categories-vedette{
	.card_wrap{
		display: flex;
		flex-wrap: wrap;
		width: 50%;

		@include break(small-screen){
			width: 100%;
		}

		.col-item{
			margin-bottom: 100px;

			&:last-of-type {
				@include break(small-screen){
					margin-bottom: 0;
				}
			}

			&:nth-of-type(3) {
				@include break(tablet){
					display: none;
				}
			}

			&:nth-of-type(2) {
				@include break(v-mobile){
					display: none;
				}
			}

			&:nth-of-type(even){
				transform: translateY(140px);

				@include break(small-screen){
					transform: none;
				}
			}

			&:last-of-type {
				@include break(small-screen){
					display: none;
				}
			}

			&:nth-of-type(3) {
				@include break(tablet){
					display: none;
				}
			}

			&:nth-of-type(2) {
				@include break(v-mobile){
					display: none;
				}
			}

			.card{
				opacity: 30%;
				max-width: 290px;

				@include break(small-screen){
					opacity: 100%;
				}

				&.active{
					opacity: 100%;
				}

				.card_img{
					background-size: cover;
				}
			}
		}
	}
}






