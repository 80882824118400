section.main-section_form{
	padding-top: $p32;
	padding-bottom: $p64;
	position: relative;
	// overflow: hidden;

	.content{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		position: relative;
	}

	.info-about-project,
	.block-form-holder{
		width: calc((100% - 32px) / 2);
		padding: $p48;
		border-radius: 10px;

		@include break(tablet){
			padding: $p40;
		}

		@include break(mobile){
			padding: $p24 $p16;
		}

		h2{
			margin-bottom: $p64;
			// max-width: 370px;

			@include break(tablet){
				max-width: none;
				margin-bottom: $p48;
			}

			@include break(mobile){
				margin-bottom: $p32;
			}

		}


	}//.info-dates, info-advantages
	.info-about-project{
		margin-top: -130px;

		@include break(tablet){
			width: 100%;
			order: 1;
			margin-bottom: $p32;
			margin-top: -80px;
		}

		ul.info-list{
			@include break(tablet){
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}

			@include break(mobile){
				display: block;
			}

			li{
				padding-bottom: $p24;
				margin-bottom: $p16;
				border-bottom: 2px solid rgba(0, 43, 61, .3);

				@include break(tablet-only){
					padding: 0;
					border: none;
					width: calc((100% - 45px) / 2);
				}

				@include break(mobile){
					padding-bottom: $p16;
					margin-bottom: $p8;
					border-width: 1px;
				}
			}
		}
	}

	.block-form-holder{
		@include break(tablet){
			width: 100%;
			order: 2;
		}
	}

	form.vote-form{
		@include break(tablet){
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.form-item{

			@include break(tablet){
				width: calc((100% - 16px) / 2);
				margin-bottom: $p16;
			}

			@include break(mobile){
				width: 100%;
			}

			&.btn-holder{
				width: 100%;
			}
		}
	}
}

::placeholder{
	color: $dark-blue;
	opacity: 20%;
}

.has-form{
	
	.form-item{
		margin-bottom: $p24;
		border-radius: 5px;
	}

	input,
	textarea{
		width: 100%;
		color: $dark-blue;
		padding: 20px 14px;
		border: none;
		border-radius: 5px;
		background: $white;
		transition: .25s;
		border: 1px solid transparent;
		resize: none;

		&.error{
			border-color:#ff0000;
		}

		@include break(mobile){
			padding: $p16 $p8;
		}

		&.btn_green{
			@extend .btn_green;
			width: auto;
		}

		&:focus{
			outline: none;
			box-shadow: 0 0 1px $dark-blue;

			&::placeholder{
				opacity: 60%;
			}
		}
	}

	.btn-holder{
		width: 100%;
		padding-top: $p24;
		display: flex;
		display: flex;
		justify-content: flex-end;
	}
}


//PAGE MERCI - FORM ENVOYE
section.form-votez-sent{
	padding: 160px 0;
	background: $light-blue;

	.content{
		display: flex;
		justify-content: space-between;
	}

	.text-side,
	.img-side{
		width: calc((100% - 140px) / 2); 

		@include break(small-screen){
			width: calc((100% - 90px) / 2);
		}

		@include break(tablet){
			width: 100%;
		}
	}

	.text-side{
		text-align: center;

		@include break(tablet){
			order: 2;
		}

		.link-return{
			width: 100%;
			margin-bottom: $p40;
			

			a{
				display: flex;
				justify-content: center;
				align-items: center;

				&:hover{
					span.icon-btn{
						background-color: $dark-blue;
					}
				}
			}

			span.icon-btn{
				height: 33px;
				width: 33px;
				background-color: $blue;
				border-radius: 50%;
				background-image: url(../img/icon_arrow.svg);
				background-size: cover;
				background-position: center;
				transition: all 0.4s;
				margin-right: 20px;
				transform: rotate(180deg);
			}
		}

		.main-text-block{
			margin-bottom: $p40;

			h1{
				margin-bottom: $p40;

				span.project-name{
					display: block;
				}
			}

			p{
				margin-bottom: $p24;
				max-width: 524px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.concours5000{
			overflow-x: hidden;
			max-width: 524px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: $p40;
			text-transform: uppercase;

			.header{
				display: inline-block;
				color: $blue;
				margin-bottom: $p16;
				position: relative;

				
				&:before,
				&:after{
					content: '';
					background: $blue;
					height: 1px;
					width: 500px;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					display: block;
				}

				&:before{
					right: calc(100% + 10px);
				}

				&:after{
					left: calc(100% + 10px);
				}
			}
		}

		.social-share{
			border: 1px solid magenta;
		}
	}

	.img-side{
		@include break(tablet){
			order: 1;
			margin-bottom: $p64;
			display: flex;
			justify-content: center;

			.img-holder{
				max-width: 300px;
			}
		}

		img{
			max-width: 100%;
			height: auto;
			display: block;
		}
	}
}


// FIXING CONTACT FORM 7 CSS
.info-about-project{
	.wpcf7-form-control-wrap{
		input{
			background: transparent; 
			border: none;
		}

		&.nomduprojet{
			width: 100%;
			display: block;

			textarea{
				max-width: 100%;
				border: none;
				background: transparent;
				outline: none;
				display: block;
				resize: none;		
			}
		}
	}
}

.block-form-holder{
	input.wpcf7-not-valid{
		background: #FFDEDE;
		border-color: #E94F35;

		&::placeholder{
			color: #E94F35;
		}
	}
	.ajax-loader{
		position: absolute;
		left: 0;
		margin: 0;
		width: 0;
		height: 0;
	}
}

.searchwp-live-search-no-min-chars:after{
	content: "Continuez d’écrire" !important;
}

.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output{
	border: none;
	color: #E94F35;
	font-size: 14px;
	padding: 0;
	text-align: right;
}

.note{
	color: red;
}








