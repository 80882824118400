html{
	overflow-x: hidden;
	overflow-y: scroll;
	&.overflow{
		overflow: hidden;
	}
	background-color: $white;
	color: $text-color;
	&::-webkit-scrollbar { width: 0 !important };
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
}

body {

	&.overflow{
		overflow: hidden;
	}
	background-color: $white;
	color: $text-color;
	font-size: $font-size;
	@include break(mobile){
		font-size: 16px;
		overflow-x: hidden;
		overflow-y: scroll;
	}
	@include font-regular;
	font-style: normal;
}

.container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	max-width: $container;
}

.container.small{
	max-width: 1040px;
}

.grecaptcha-badge{
	visibility: hidden !important;
}

.half-left {
	padding-left: calc((100vw - 1440px) / 2);
	@include break(container) {
		padding-left: 5%;
	}
	width: 50%;
}
.half-right {
	padding-right: calc((100vw - 1440px) / 2);
	@include break(container) {
		padding-right: 5%;
	}
	width: 50%;
}

.mobile {
	display: none;
	@include break(mobile) {
		display: block;
	}
}
.desktop{
	@include break(mobile) {
		display: none;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

// TITRE
h1, .h1 {
	@include font-black;
	font-size: font-em(50px, 16px);
	line-height: 1.2em;
	margin: 0;
	padding: 0;

	@include break(mobile) {
		font-size: font-em(40px, 16px);
	}

	@include break(v-mobile) {
		font-size: font-em(30px, 16px);
	}
}
h2, .h2 {
	@include font-bold;
	font-size: font-em(40px, 16px);
	line-height: 1.25em;
	margin: 0;
	padding: 0;

	@include break(tablet){
		font-size: font-em(35px, 16px);
	}

	@include break(mobile){
		font-size: font-em(32px, 16px);
	}

	@include break(mobile){
		font-size: font-em(28px, 16px);
	}
}

h3, .h3 {
	@include font-bold;
	font-size: font-em(25px, 16px);
	line-height: 1.2em;
	margin: 0;
	padding: 0;
}
h4, .h4 {
	@include font-bold;
	font-size: font-em(22px, 16px);
	line-height: 1.22em;
	text-transform: uppercase;
	margin: 0;
	padding: 0;
}
h5, .h5 {
	@include font-bold;
	font-size: font-em(20px, 16px);
	line-height: 1.25em;
	margin: 0;
	padding: 0;
}
h6, .h6 {
	@include font-bold;
	font-size: font-em(16px, 16px);
	line-height: 1.25em;
	margin: 0;
	padding: 0;
}


// BG
.bg_light-blue {
	background-color: $light-blue;
}
.bg_blue {
	background-color: $blue;
	color: $white;
}
.bg_light-green {
	background-color: $light-green;
}
.bg_green {
	background-color: $green;
}


// BOX
.radius{
	border-radius: $border-radius;
}

.card {
	position: relative;
	z-index: 1;
	cursor: pointer;

	&_img {
		width: 288px;
		height: 370px;
		position: relative;
		background-size: 235%;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		border-radius: 10px;
		margin-bottom: $p24;
		&:before {
			content: " ";
			@include box-shadow;
			opacity: 0;
		}

		.play {
			@include absolute-center(center);
			transform: translate(-50%, -50%) scale(0.9);
			opacity: 0;
			transition: all 0.4s;
		}
	}

	&:hover &_img {
		:before {
			opacity: 1;
			transform: translateY(0px);
		}
		.play {
			opacity: 1;
			transform: translate(-50%, -50%) scale(1);
		}
	}

	.cat_grey {
		text-transform: uppercase;
		color: $grey;
		font-size: 0.875em;
		padding-bottom: $p16;
	}

	h5 {
		padding-bottom: $p16;
	}
}

.btn{
	cursor: pointer;
}

.btn_blue {
	@include btn;
	background-color: $blue;
	color: $white;
}

.btn_lightblue{
	@include btn;
	background-color: $light-blue;
	color: $dark;
}

.btn_green {
	@include btn;
	background-color: $green;
	color: $white;
}

.btn_white {
	@include btn;
	background-color: $white;
	color: $dark-blue;
}

.btn_transparent{
	@include btn;
	color: $dark-blue;
	border: 2px solid $dark-blue;

	&:hover{
		background: $light-blue;
		color: $dark-blue;
		border-color: $light-blue;
	}
}

.btn.disabled{
	background: #D2D2D2;
	cursor: auto;
	pointer-events: none;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(255,255,255,0.8);
	z-index: 9999;
	display: none;

	.video {
		@include absolute-center(center);
		width: 80%;
		padding-bottom: 45%;
		height: 0;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.close {
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;

		circle {
			transition: fill 0.4s;
		}

		&:hover circle {
			fill: $dark-blue;
		}
	}
}

.section--title{
	&.center{
		text-align: center;
	}
}

.banner-secondary{
	background: $light-blue;
	position: relative;
	// max-width: 1920px;
	margin: 0 auto;
	overflow-x: hidden;

	&:before{
		content: '';
		width: 40vw;
		height: 100%;
		background-image: url(../img/banner-icons_bg.svg);
		// background-size: cover;
		position: absolute;
		// background-size: 187%;
		background-position-y: 36%;
		left: 66%;
		top: 0;
		display: block;

		@include break(mobile){
			background-size: 130vw;
			opacity: 50%;
		}
	}

	.banner-sec-flex{
		min-height: 570px;
		display: flex;
		align-items: center;
		padding: 180px 0;

		@include break(tablet){
			height: auto;
			padding: 120px 0;
		}

		.textbox{
			max-width: 495px;
			position: relative;
		}
	}

	.breadcrumb-holder{
		display: flex;
		align-items: flex-start;
		margin-bottom: $p24;
		white-space: nowrap;

		.icon-holder{
			margin-right: $p16;
			min-width: 24px;
		}

		ul.breadcrumb-list{
			display: flex;

			li{
				font-size: 12px;
				margin-right: $p32;
				position: relative;
				font-weight: 300;

				&:first-letter{
					text-transform: uppercase;
				}

				a{
					color: $blue;
					font-weight: 700;
				}

				&:before{
					content: '/';
					position: absolute;
					left: calc(100% + 16px);
					top: 0;
				}

				& + li:before{
					content: '';
				}
			}
		}
	}

	//Text elements
	h1, .title{
		margin-bottom: $p16;
	}
	h2, .subtitle{
		padding-left: 90px;
		position: relative;
		text-transform: uppercase;
		margin-bottom: $p32;

		&:before{
			content: url(../img/icon_quotes.svg);
			position: absolute;
			left: 0%;
			top: 0;
			display: block;
		}
	}

	.btns-holder{
		padding-top: $p32;

		a{
			margin-right: $p8;
			margin-bottom: $p8;
			cursor: default;

			&:hover{
				background: $white;
				color: $dark-blue;
				border-color: transparent;
			}
		}
	}
}

.has--wysiwyg{
	h1,h2,h3,h4,h5,h6,p{
		margin-bottom: $p24;
	}
}

.social-media-share{
	display: flex;

	li{
		margin-right: $p16;

		a, span{
			background: $blue;
			width: 33px;
			height: 33px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: .3s;
			cursor: pointer;

			&:hover{
				background: $dark-blue;
			}

			img{
				cursor: pointer;
			}
		}
	}

	&.center{
		justify-content: center;

		li{
			margin: 0 11px;
		}
	}
}





// CARD ON:
// single part., archive part., homepage palmares
.card.default{
	.image{
		width: 100%;
		height: 370px;
		border-radius: 10px;
		background-size: cover;
		background-position: center;

		@include break(tablet){
			height: 300px;
		}
	}

	.info{
		padding: $p24 0;

		.year{
			color: $grey;
			margin-bottom: $p24;
			text-transform: uppercase;
		}

		.product-type{
			margin-bottom: $p8;
		}

		.product-categories{
			font-size: 14px;
		}

		.main-info{
			// margin-bottom: $p8;
			padding-bottom: 0;

			&:last-of-type{
				margin-bottom: $p24;
			}
		}
	}

	&.grid-model{
		width: calc((100% - 180px) / 3);
		margin-bottom: 80px;

		@include break(mobile){
			width: calc((100% - 60px) / 2);
			margin-bottom: 40px;
		}

		@include break(v-mobile){
			width: 100%;
			margin-bottom: 20px;
		}

		&.phantom-card{
			height: 0;
		}
	}

	&.palmares{
		position: relative;
		

		.image{
			position: relative;

			.number{
				position: absolute;
				bottom: 0;
				right: 5px;
				font-size: 120px;
				color: white;
				line-height: 1em;
			}
		}

		.card--link{
			&:before{
				content: '';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				position: absolute;
				cursor: pointer;
			}
		}
	}
}




// NOUVELLES PAGES (A ORGANISER)
.before-start,
.faq-participer{
	padding: 160px 0;

	@include break(tablet){
		padding: 100px 0;
	}

	@include break(v-mobile){
		padding: 50px 0;
	}

	.container{
		align-items: flex-start !important;
		
		.content{
			display: flex;
			justify-content: space-between;

			@include break(tablet){
				display: block;
			}

			.img-holder,
			.content-text{
				width: calc((100% - 80px) / 2);

				@include break(tablet){
					width: 100%;
				}
			}

			.img-holder{
				height: 100%;
				position: relative;

				@include break(tablet){
					max-width: 300px;
					margin-left: auto;
					margin-right: auto;
				}

				.bg{
					width: 100%;
				}

				.logo_adaq{
					width: 27%;
					@include absolute-center(center);
				}
			}

			.content-text{
				text-align: left;
				padding-top: $p128;

				@include break(small-screen){
					padding-top: $p64;
				}

				@include break(tablet){
					width: 100%;
					text-align: center;
				}

				.text-holder{
					max-width: 450px;

					@include break(tablet){
						max-width: 600px;
						margin: 0 auto;
					}
				}

				.has-wysiwyg{
					padding-top: $p24 !important;

					em{
						font-style: normal;
						color: $blue;
						margin-bottom: $p24;
					}

					h5{
						text-transform: uppercase;
						margin-bottom: $p24;
					}

					p{
						padding-top: 0;
						margin-bottom: $p24;
					}

					a{
						@extend .btn_blue;
						margin-top: 0;
					}
				}//.has-wysiwyg

				.page-title-subtitle{
					@include break(tablet){
						text-align: center;
					}

					h2,
					.page-description,
					h5{
						margin-bottom: $p40;
					}

					h5{
						text-transform: uppercase;
					}
				}
			}
		}
	} //.container

	.select-box{
		background: #C9E8FB;
		width: 288px;
		position: relative;
		margin-bottom: 30px;
		border-radius: 5px;
		border-bottom: 2px solid transparent;
		cursor: pointer;

		@include break(tablet){
			margin-left: auto;
			margin-right: auto;
			text-align: left !important;
		}

		&.is-open{
			.selected:before{
				transform: translateY(-50%) rotate(135deg);
			}
		}

		.selected{
			width: 288px;
			padding: 12px;
			// background: #C9E8FB;
			border-radius:5px;
			position: relative;

			&:before{
				content: '';
				width: 7px;
				height: 7px;
				position: absolute;
				right: 14px;
				top: 50%;
				transform-origin: center;
				transform: translateY(-50%) rotate(-45deg);
				border-left: 2px solid $dark;
				border-bottom: 2px solid $dark;
				transition: .25s;
			}
		}

		ul.options-list{
			border-radius: 5px;
			background: white;
			position: absolute;
			display: none;
			top: 100%;
			left: 0;
			width: 100%;
			box-shadow: 0 2px 3px rgba(77, 77, 77, 0.3);
			z-index: 2;

			li{
				padding: 12px;
				border-radius: 5px;
				transition: .25s;
				cursor: pointer;

				&:hover{
					background: #e4e4e4;
				}

			}
		}
	}

	.msg-boxes{
		background: #E3F3FD;
		padding: 30px 60px 30px 30px;
		border-radius: 10px;
		position: relative;
		display: none;
		text-align: left;

		@include break(tablet){
			margin-left: auto;
			margin-right: auto;
			max-width: 300px;
			padding: 20px;
		}

		a{
			font-weight: 700;
			text-decoration: underline;

			&[target="_blank"]{
				position: relative;
				display: inline-block;
				padding-right: 25px;

				&:before{
					content: url(../img/icon_link_ext.svg);
					position: absolute;
					right: -2px;
					top: -1px;
				}
			}
		}

		&:before{
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 14px 18px 14px;
			border-color: transparent transparent #E3F3FD transparent;
			position: absolute;
			bottom: 100%;
			left: 20px;
		}

		.msg-box{
			display: none;

			p{
				margin-bottom: 20px;
			}
		}
	}

	.btn-holder{
		margin: 40px 0;
	}

	.return-home{
		display: none;
		align-items: center;
		position: relative;

		@include break(tablet){
			justify-content: center;
		}
		
		.link-home{
			&:before{
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				cursor: pointer;
			}
		}

		.icon-holder{
			margin-right: 20px;
			width: 33px;
			height: 33px;
			background: $blue;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;

			svg{
				transform: rotate(180deg);
			}
		}
	}
}


//SPECIFIC FAQ - participer
.faq-participer{
	padding: 160px 0;

	@include break(tablet){
		padding: 100px 0;
	}

	@include break(v-mobile){
		padding: 50px 0;
	}

	.container{
		.content{
			.content-text{
				text-align: left;
				padding-top: 0;

				.text-holder{
					max-width: 610px;
				}
			}
		}
	} //.container

	.btn-cta-holder{
		padding-top: 40px;
		padding-right: 40px;
		text-align: right;

		@include break(mobile){
			padding-top: 20px;
			padding-right: 20px;
			text-align: center;
		}
	}

	.img-holder{
		@include break(tablet){
			max-width: 400px;
			margin-bottom: 60px;
		}
	}

	.faq-section{

		li{
			text-align: left;
		}

		.faq-item{
			background: $light-green;
			padding: 40px;
			margin-bottom: 15px;
			position: relative;
			border-radius: 10px;
			cursor: pointer;

			@include break(mobile){
				padding: 25px;
			}

			&.active{
				.question{
					.icon-holder{
						border-color: $dark;
						background: transparent;

						&:before,
						&:after{
							background: $dark;
							transform: translate(-50%, -50%) rotate(180deg);
						}
					}
				}
			}

			.question{
				text-transform: uppercase;
				padding-right: 75px;
				display: flex;

				@include break(mobile){
					padding-right: 60px;
				}

				h4{
					@include break(mobile){
						font-size: 20px;
					}
				}

				.icon-holder{
					background: $green;
					width: 44px;
					height: 44px;
					border: 1px solid $green;
					position: absolute;
					right: 40px;
					top: 28px;
					border-radius: 50%;
					display: block;
					transition: .25s;

					@include break(mobile){
						width: 32px;
						height: 32px;
						right: 22px;
						top: 22px;
					}

					&:before,
					&:after{
						content: '';
						width: 20px;
						height: 1px;
						background: white;
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						transition: .25s;
						transform-origin: center;

						@include break(mobile){
							width: 15px;
						}
					}

					&:before{
						transform: translate(-50%, -50%) rotate(0deg);
					}

					&:after{
						transform: translate(-50%, -50%) rotate(90deg);
					}
				}
			} //question

			.answer{
				padding-top: 40px;
				text-align: left;
				display: none;

				h2,
				h3,
				h4,
				h5,
				h6{
					margin-bottom: 20px;
				}

				p{
					margin-bottom: 15px;

					&:last-of-type{
						margin-bottom: 0;
					}
				}

				a{
					color: $blue;
					text-decoration: underline;
				}

				&.regular-editor{

				}

				&.ticlist{
					ul{
						margin-bottom: 30px;
						list-style: none;
						padding-left: 0;

						li{
							margin-bottom: 20px;
							font-weight: 500;
							padding-left: 65px;
							position: relative;

							&:before{
								content: url(../img/ticklist.svg);
								position: absolute;
								left: 0;
								top: 0;
							}
						}
					}

					.box-attention{
						background: rgba(165, 200, 61, 0.29);
						padding: 30px;
						border-radius: 10px;
						margin-bottom: 30px;

						@include break(mobile){
							padding: 15px;
						}

						p{
							font-size: 15px;
							font-weight: 500;
						}
					}

					.list-legende{
						font-size: 14px;
						font-weight: 300;
						margin-bottom: 8px;

						p{
							margin-bottom: 8px;
						}
					}
				} //.ticlist

				&.steps-follow{

					ol{
						counter-reset: li-count;
						list-style: none;
						// padding-left: 0;

						li {
						  counter-increment: li-count;
						  margin-bottom: 20px;
						  position: relative;
						  padding-left: 20px;
						}

						li:before {
						  content: '';
						  font-weight: bold;
						  background: rgba(165, 200, 61, 0.29);
						  min-width: 37px;
						  min-height: 37px;
						  display: flex;
						  justify-content: center;
						  align-items: center;
						  border-radius: 50%;
						  display: inline-block;
						  position: absolute;
						  left: -40px;
						}

						li:after{
							content: counter( li-count) ;
							position: absolute;
							left: -26px;
							top: 7px;
						}
					}
				} //.steps-follow

				&.important-dates{
					ul.info-dates-list{

						@include break(tablet){
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							position: relative;
						}
						
						li{
							margin-bottom: $p32;
							display: flex;

							// @include break(tablet){
							// 	width: calc((100% - 32px) / 2);
							// 	margin-bottom: $p32;
							// }

							// @include break(v-mobile){
							// 	width: 100%;
							// }

							.icon-holder{
								min-width: 44px;
								margin-right: $p24;
								// padding-top: 5px;
							}

							.text-holder{
								max-width: 254px;

								@include break(v-mobile){
									max-width: none;
								}
							}

							h4{
								text-transform: uppercase;
								margin-bottom: 5px;
							}

							&:last-of-type{
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}


// PAGE NOS ANCIENS PARTICIPENTS

.slider-section{
	padding: 50px 0;

	.flex{
		display: flex;
		justify-content: space-between;
		

		@include break(mobile){
			flex-wrap: wrap;
		}

		.left-box,
		.right-box{
			width: calc((100% - 18px) / 2);
			height: 485px;
			border-radius: 10px;
			padding: 40px 45px;

			@include break(tablet){
				height: 400px;
				padding: 35px 40px;
			}

			@include break(mobile){
				width: 100%;
				height: 300px;
				padding: 30px 35px;
			}
		}

		.left-box{
			background: $green;

			@include break(mobile){
				margin-bottom: 20px;
				height: auto;
			}

			h2{
				max-width: 312px;
				margin-bottom: 27px;
			}

			.arrow{
				transform: translateX(-10px);
				opacity: 10%;
				animation: 4s movearrow infinite;
			}
		}

		.right-box{
			background: $light_green;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@include break(mobile){
				height: auto;
				padding-bottom: 40px;
			}

			.numbers{
				font-size: 80px;
				color: $green;
				line-height: 0.8;

				@include break(mobile){
					font-size: 60px;
					margin-bottom: 20px;
				}

				.value{
					font-weight: 900;
				}

				.unit{
					font-weight: 700;
				}
			}
			.slider-holder-op {
				width: 325px; 

				@include break(v-mobile){
					width: auto;
				}

				.slick-list{
					max-width: 325px;
				}

				// Slide
				.item-slide{
					padding-bottom: $p16;

					@include break(mobile){
						padding-bottom: 0;
					}

					p{
						@include break(mobile){
							margin-bottom: 15px;
						}
					}
				}

				ul.slick-dots{
					display: flex;
					transform: translateY(-3px);

					@include break(v-mobile){
						max-width:225px;
						width: 100%;
						margin-left: auto;
						margin-right: auto;
					}

					li{
						flex: 1;
						height: 4px;
						border-radius: 2px;
						margin-right: 4px;

						button{
							background: #DDE7BC;
							border: none;
							font-size: 0;
							width: 100%;
							border-radius: 2px;
							height: 4px;
						}

						&.slick-active{
							button{
								background: $green;
							}
						}
					}
				}
			}
		}
	}
}

@keyframes movearrow{
	0%{
		opacity: 10%;
		transform: translateX(-10px);
	}

	30%{
		opacity: 100%;
		transform: translateX(0);
	}

	100%{
		opacity: 100%;
		transform: translateX(0);
	}
}

.template-archive-previous-participants{

	.btn-year.active{
		background: $light-blue;
		border-color: $light-blue;
	}
	.slider-holder{
		background: $light_green;
		padding: 50px 0;
	}

	.previous-participants{
		padding: 30px 0;

		.section--title{
			h2{
				margin-bottom: 50px;
			}
		}

		&:last-of-type {
			margin-bottom: 50px;
		}

		.card{
			// opacity: 30%;
			// display: none;
		}

		.phantom-card-edit{
			width: calc((100% - 180px) / 3);
			height: 0;

			@include break(mobile){
				display: none;
			}
		}

		.filter-holder{
			.btns-holder{
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 30px;

				@include break(v-mobile){
					flex-direction: column;
				}

				.btn{
					margin-right: 7px;
					margin-left: 7px;

					@include break(v-mobile){
						margin-bottom: 10px;
					}
				}

				.filter-btn{
					position: relative;

					span{
						margin-right: 3px;
					} 

					svg{
						position: absolute;
						left: 15px;
						top: 8px;
					}

					.btn-close{
						width: 24px;
						height: 24px;
						background: $dark;
						border-radius: 50%;
						display: block;
						position: absolute;
						right: 8px;
						top: 8px;
						margin-right: 0;
						border: 1px solid $dark;
						transition: .25s;
						opacity: 0;


						&:before,
						&:after{
							content: '';
							width: 12px;
							height: 2px;
							display: block;
							transform-origin: center;
							background: white;
							position: absolute;
							top: 50%;
							left: 50%;
							transition: .25s;
							transform: translate(-50%, -50%) rotate(-45deg);
						}

						&:after{
							transform: translate(-50%, -50%) rotate(45deg);
						}
					}

					&:hover{
						background: $dark;
						color: $white;

						.btn-close{
							background: transparent;
						}
					}
				}
			}

			.years-btns{
				opacity: 0;
				transform: translateY(-10px);
				transition: .25s;

				.btn{
					margin: 3px 7px;
				}
			}
		}

		.content{
			padding-top: 50px;
			.flex{

				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}
		}
	}
}

.filter-holder.show-filters{

	.years-btns{
		opacity: 100% !important;
		transform: translateY(0) !important;
	}

	.filter-btn{
		background: $dark;
		color: $white;

		.btn-close{
			opacity: 100% !important;
			border-color: white !important;
		}
	}
}




















