/*  --- Variable ---  */
@use 'sass:math';
//FONT

@mixin font-regular{
  font-family: museo-sans, sans-serif;
  font-weight: 500;
}
@mixin font-light{
  font-family: museo-sans, sans-serif;
  font-weight: 300;
}
@mixin font-bold{
  font-family: museo-sans, sans-serif;
  font-weight: 700;
}
@mixin font-black{
  font-family: museo-sans, sans-serif;
  font-weight: 900;
}


/* --- Variables for fonction --- */

$font-size: 16px;


//COLOR

$dark-blue: #002B3D;
$blue: #009FE3;
$light-blue: #C9E8FB;
$green: #A5C83D;
$light-green: #F2F7E2;
$red: #E94F35;
$lighter-blue: #E3F3FD;

$white: #ffffff;
$dark: #000000;
$grey: #9A9A9A;

$text-color: $dark-blue;

$container: 1440px;

// Autre
$border-radius: 10px;

$p_base: math.div($font-size, 2);

//8px
$p8: $p_base * 1;
// 16px
$p16: $p_base * 2;
// 24px
$p24: $p_base * 3;
// 32px
$p32: $p_base * 4;
// 40px
$p40: $p_base * 5;
// 48px
$p48: $p_base * 6;
// 64px
$p64: $p_base * 8;
// 80px
$p80: $p_base * 10;
// 128px
$p128: $p_base * 16;
// 240px
$p240: $p_base * 30;


@mixin gradient-hover {
  background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.50) 100%);
  height: 90px;
}

@mixin box-shadow {
  background: #FFFFFF;
  box-shadow: 0 2px 40px 0 rgba(0,0,0,0.5);
  border-radius: 10px;
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: $p32;
  right: $p32;
  top: 50px;
  transform: translateY(-20px);
  transition: all 0.8s;
}

@mixin btn {
  text-transform: uppercase;
  font-size: font-em(15px, 16px);
  padding: $p_base $p24;
  border-radius: 35px;
  min-width: 150px;
  min-height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
  border: 2px solid transparent;

  &:hover {
    border-color: $dark-blue;
    background-color: transparent;
    color: $dark-blue;
  }
}
