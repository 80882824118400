.discover-a-project{
	.container{
		padding: $p80 0;
	}

	.section--title{
		margin-bottom: $p40;

		h2{
			margin-bottom: $p40;
		}

		p{
			max-width: 600px;
			margin: auto;
		}
	}

	.content{
		text-align: center;
	}
}

.filter--projects{
	.container{
		padding: $p128 0 $p40;

		.content{
			max-width: 1040px;
			margin: auto;
		}
	}

	.filter-options{
		ul.filter-options-list{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			li.item{
				margin: 8px 4px;

				@include break(tablet){
					a.btn_transparent{
						font-size: 14px;
						padding: 7px 22px;
					}
				}

				@include break(mobile){
					a.btn_transparent{
						min-width: 0;
						min-height: 0;
						font-size: 12px;
						padding: 6px 18px;
					}
				}

				@include break(v-mobile){
					a.btn_transparent{
						font-size: 10px;
						padding: 6px 16px;
					}
				}

				&.active{
					a{
						background: $light-blue;
						border-color: $light-blue;
					}
				}
			}
		}

		&.per-region{
			ul.filter-options-list{
				// max-width: 800px;
				margin: $p32 auto;
				padding: $p48;
			}
		}

		&.per-type{
			background: $lighter-blue;
			padding: $p32;
			border-radius: 10px;
			margin-bottom: $p40;

			display: none;

			&.open-me{
				display: block;
			}

			h5,
			.block-title{
				margin-bottom: $p24;
				text-align: center;
				text-transform: uppercase;
			}
		}

		&.per-edition{
			// background: $lighter-blue;
			padding: $p32;
			// border-radius: 10px;

			display: none;

			&.open-me{
				display: block;
			}

			h5,
			.block-title{
				margin-bottom: $p24;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}//.filter-options

	.filter-results{
		padding: 150px 0;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		a.link{
			// &:before{
			// 	content: '';
			// 	display: block;
			// 	position: absolute;
			// 	top: 0;
			// 	bottom: 0;
			// 	left: 0;
			// 	right: 0;
			// }
		}

		.totalresults{
			position: absolute;
			top: 40px;
			left: 50%;
			transform: translateX(-50%);
		}

		.initial-content{
			max-width: 400px;
			margin: auto;
			text-align: center;
			// border: 2px solid red;

			svg{
				margin-bottom: $p16 ;
			}

			h4{
				color: $dark-blue;
				opacity: 40%;
				text-transform: uppercase;
			}
		}

		h5{
			text-align: center;
		}
	}
}











