.pre_footer {
  display: flex;

  .half-left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: $p64;
    text-align: center;

    @include break(mobile){
      width: 100%;
      padding: 0 20px;
    }


    .content {
      padding-top: $p240;
      padding-bottom: $p240;
      position: relative;
      z-index: 1;

      @include break(mobile){
        padding: 80px 30px;
      }

    }

    .bg {
      width: 55%;
      max-width: 550px;
      z-index: -1;
      position: absolute;
      top: 35%;
  		left: 50%;
  		transform: translate(-50%, -50%);

      @include break(mobile){
        max-width: 250px;
      }
    }

    h2 {
      padding-bottom: $p32;
    }

    a {
      margin-top: $p32;
    }
  }

  .half-right {
    position: relative;
    overflow: hidden;

    @include break(mobile){
      display: none;
    }

    img {
      width: auto;
      min-width: 0;
      max-width: 750px;
      height: 100%;
      @include absolute-center(center);
    }
  }
}


footer {
  .footer {
    padding-top: $p64;
    padding-bottom: $p64;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include break(tablet){
      flex-wrap: wrap;
    }

    >div {
      width: 50%;
      @include break(tablet){
        width: 100%;
        text-align: center;
      }
    }
  }
  .col_menu {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-right: 1px solid $blue;

    // border: 2px solid red !important;

    @include break(small-screen){
      flex-wrap: wrap;
    }

    @include break(tablet){
      order: 2;
      border: none;
    }

    @include break(v-mobile){
      flex-wrap: wrap;
      text-align: center;
    }

    >div {
      width: 50%;
      // border: 1px solid blue;

      @include break(v-mobile){
        width: 100%;
      }

      &.footer_nav{
        @include break(v-mobile){
          order: 2;
          padding-top: $p24;
          border-top: 1px solid $light-blue;
        }
      }

      &.footer_nav li {
        margin-bottom: $p24;

        @include break(v-mobile){
          color: $blue;
          text-transform: uppercase;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &.info {
      padding-right: $p128;
      font-weight: 500;

      @include break(small-screen){
        padding-right: $p80;
      }

      @include break(tablet){
        padding-right: 0;
      }

      @include break(v-mobile){
        margin-bottom: $p32;
      }
        h5 {
          font-weight: 700;
        }
      }
    }
  }

  .col_medias {
    padding-left: $p128;

    @include break(small-screen){
      padding-left: $p80;
    }

    @include break(tablet){
      padding-left: 0;
      text-align: center;
      margin-bottom: $p80;
    }

    h6 {
      color: $blue;
      text-transform: uppercase;
      margin-bottom: $p16;
    }

    .medias {
      display: flex;
      margin-bottom: $p48;

      @include break(small-screen){
        flex-wrap: wrap;
      }

      @include break(tablet){
        justify-content: center;
      }

      @include break(v-mobile){
        justify-content: space-around;
        max-width: 240px;
        margin-left: auto;
        margin-right: auto;
      }

      .media-item{
        height: auto;

        @include break(v-mobile){
          width: calc(100% / 3);
          margin-bottom: 10px;
        }
      }

      a {
        margin-right: $p16;
        margin-bottom: $p16;
        transition: all 0.4s;
        height: 45px;
        width: 45px;
        background-color: $blue;
        border-radius: 50%;
        position: relative;
        transition: all 0.4s;
        display: inline-block;

        @include break(v-mobile){
          margin-bottom: 0;
          margin-right: 0;
        }

        img {
          @include absolute-center(center);
        }
        &:hover {
          background-color: $dark-blue;
        }
      }
    }

    form {

      display: inline-block;
      position: relative;

      @include break(v-mobile){
        width: 100%;
      }
      input[type='email'],
       input[type='search']{
        appearance: none;
        outline: none;
        padding: $p_base $p24;
        border-radius: 35px;
        height: 45px;
        min-width: 350px;
        border: 2px solid transparent;
        background-color: $light-blue;

        @include break(v-mobile){
          min-width: 0;
          width: 100%;
        }
      }
      input[type='submit'] {
        height: 45px;
        width: 45px;
        background-color: $blue;
        appearance: none;
        outline: none;
        border: none;
        border-radius: 50%;
        font-size: 0;
        color: $blue;
        position: absolute;
        right: 0;
        top: 0;
        background-image: url(../img/icon_arrow.svg);
        background-size: cover;
        background-position: center;
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          background-color: $dark-blue;
        }
      }
    }
  }

  .copyright {
    text-align: center;
    font-weight: 300;
    font-size: 0.875em;
    padding-top: $p_base;
    padding-bottom: $p_base;
  }
}
