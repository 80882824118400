.acf-map {
    width: 100%;
    height: 600px;
    margin: 0;
    img {
        max-width: inherit !important; // Fixes potential theme css conflict.
     }
}

.section-map{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    margin-top: 60px;

    h2{
        text-align: center;
    }
    .container-map{
        width: 100%;
        display: flex;
        gap: 64px;
        align-items:flex-start;
        justify-content: center;
        margin-top: 56px;
        .col-left{
            display: flex;
            flex-direction: column;
            width: 70%;
        }
        .col-right{
            display: flex;
            width: 30%;
            .map-text-info{
                display: flex;
                flex-direction: column;
                gap: 40px;
                .header-content-text{
                    display: flex;
                    flex-direction: column;
                    gap:16px;
                    color: $dark-blue;
                    h3{
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                    }
                    p{
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
                .content-adress-info{
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    background-color: $light-blue;
                    padding: 32px 40px;
                    border-radius: 8px;
                    transition: .4s all;
                    .headerContent{
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        h4{
                            color: $dark-blue;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                        }
                        h5{
                            color: $blue;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                        }
                        p{
                            display: block;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                        }
                    }
                    .bodyContent{
                        display: block;
                        flex-direction: column;
                        a.link-post:link,
                        a.link-post:visited{
                            display: inline-block;
                            transition: all 0.4s;
                            padding: 11px 24px;
                            border-radius: 35px;
                            background: $blue;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 600;
                            text-transform: uppercase;
                            color: white;
                            border: 2px solid transparent;
                        }
                        a.link-post:hover{
                            border-color: $dark-blue;
                            background-color: transparent;
                            color: $dark-blue;
                        }
                    }
                }
            }
        }
    }

}


#map {
    height: 600px;
    width: 100%;
}
.content-field{
    position: relative;
    .search-input {
        width: 100%;
        height: 46px;
        background-color: $light-blue;
        border: none;
        height: 45px;
        border-radius: 28px;
        padding: 0 54px 0 15px;
        font-size: 1rem; 
        font-weight: 500;
        color: $dark;
        &::placeholder {
            color: $blue;
            font-size: 1rem; 
            font-weight: 500;
            opacity: 1;
        }
    }
    &:after{
        position: absolute;
        top: 0;
        right: 1px;
        content:'';
        width: 46px;
        height: 46px;
        border-radius: 40px;
        background: url("../img/search.svg") no-repeat center $blue;
    }
}

/* Prevent hiding zoom controls */
.gm-style {
    .gm-style-iw-c {
        overflow: hidden !important;
    }
    .gmnoprint img {
        max-width: none;
    }
}

/* Style for the input field */
.pac-input {
    width: 300px; /* Set the width of the input field */
    padding: 10px; /* Add padding */
    font-size: 16px; /* Set the font size */
}

/* Style for the autocomplete dropdown */
.pac-container {
    background-color: #DFF1FD; /* Set background color */
    border-radius: 10px;
    box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.12); /* Add shadow */
    z-index: 1000; /* Set z-index */
}

/* Style for the autocomplete dropdown items */
.pac-item {
    padding: 10px; /* Add padding */
    font-size: 16px; /* Set font size */
    cursor: pointer; /* Set cursor to pointer */
}

/* Style for the autocomplete dropdown items on hover */
.pac-item:hover {
    background-color: #f0f0f0; /* Change background color on hover */
}

.marker{
    display: none;
}

@media (max-width: 912px) {
    .section-map{
        width: 100%;
        .container-map{
            flex-direction: column;
            position: relative;
            gap: 20px;
            margin-top: 40px;
            .acf-map{
                margin-top: 188px;
            }
            .col-left{
                width: 100%;
            }
            .col-right{
                width: 100%;
                .map-text-info{
                    width: 100%;
                    .header-content-text{
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 90%;
                    }
                    .content-field{
                        position: absolute;
                        top: 112px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 90%;
                    }
                    #adress-info{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        .content-adress-info{
                            width: 90%;
                        }
                    }
                }
            }
        }
    }
}