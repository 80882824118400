.section-vote{

	.container{
		align-items: flex-start !important;
		
		.content{
			display: flex;
			justify-content: space-between;

			@include break(tablet){
				display: block;
			}

			.img-holder,
			.content-text{
				width: calc((100% - 80px) / 2);

				
			}

			.img-holder{
				height: 100%;
				position: relative;

				@include break(tablet){
					max-width: 300px;
					margin-left: auto;
					margin-right: auto;
				}

				.bg{
					width: 100%;
				}

				.logo_adaq{
					width: 27%;
					@include absolute-center(center);
				}
			}

			.content-text{
				text-align: left;
				padding-top: $p128;

				@include break(small-screen){
					padding-top: $p64;
				}

				@include break(tablet){
					width: 100%;
					text-align: center;
				}

				.text-holder{
					max-width: 450px;

					@include break(tablet){
						max-width: 600px;
						margin: 0 auto;
					}
				}

				.has-wysiwyg{
					padding-top: $p24 !important;

					em{
						font-style: normal;
						color: $blue;
						margin-bottom: $p24;
					}

					h5{
						text-transform: uppercase;
						margin-bottom: $p24;
					}

					p{
						padding-top: 0;
						margin-bottom: $p24;
					}

					a{
						@extend .btn_blue;
						margin-top: 0;
					}
				}//.has-wysiwyg

				.page-title-subtitle{
					h2,
					.page-description,
					h5{
						margin-bottom: $p40;
					}

					h5{
						text-transform: uppercase;
					}
				}
			}
		}
	} //.container

	.block-search-relevanssi{
		border: 2px solid magenta;
		padding: 4px;
	}

	.search-projects{
		form{
			//display: inline-block;
     		 position: relative;
			}
		input[type='search']{
			width: 100%;
			appearance: none;
			outline: none;
			padding: $p_base $p24;
			border-radius: 35px;
			height: 45px;
			min-width: 350px;
			border: 2px solid transparent;
			background-color: $light-blue;
		}

		span {
		  height: 45px;
		  width: 45px;
		  background-color: $blue;
		  appearance: none;
		  outline: none;
		  border: none;
		  border-radius: 50%;
		  font-size: 0;
		  color: $blue;
		  position: absolute;
		  right: 0;
		  top: 0;
		  background-image: url(../img/icon_arrow.svg);
		  background-size: cover;
		  background-position: center;
		  cursor: pointer;
		  transition: all 0.4s;

		  &:hover {
		    background-color: $dark-blue;
		  }
		}
	}
}

