section.information-contest{
	padding-top: $p32;
	padding-bottom: 300px;
	position: relative;
	// overflow: hidden;

	@include break(tablet){
		padding-bottom: $p128;
	}

	.section-bg-img{//previously &:before non editable
		content: '';
		background-size: cover;
		width: 550px;
		height: 530px;
		position: absolute;
		bottom: 0;
		right: 0;
		display: block;
	}

	.content{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		position: relative;
	}

	.info-dates,
	.info-advantages{
		width: calc((100% - 32px) / 2);
		padding: $p48;
		border-radius: 10px;
		position: relative;

		@include break(mobile){
			padding: $p40;
		}

		h2{
			margin-bottom: $p64;

			@include break(small-screen){
				margin-bottom: $p40;
			}

		}

		ul.info-dates-list,
		ul.info-adv-list{

			@include break(tablet){
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				position: relative;


				&:before{
					// content: '';
					background: $dark;
					opacity: .2;
					width: 1px;
					height: 100%;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}
			
			li{
				margin-bottom: $p32;
				display: flex;

				@include break(tablet){
					width: calc((100% - 32px) / 2);
					margin-bottom: $p32;
				}

				@include break(v-mobile){
					width: 100%;
				}

				.icon-holder{
					min-width: 44px;
					margin-right: $p24;
					// padding-top: 5px;
				}

				.text-holder{
					max-width: 254px;

					@include break(v-mobile){
						max-width: none;
					}
				}

				h4{
					text-transform: uppercase;
				}

				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
	}//.info-dates, info-advantages
	.info-dates{
		@include break(tablet){
			width: 100%;
			order: 2;
		}

		h2{
			@include break(desktop-only){
				max-width: 370px;
			}
		}
	}

	.info-advantages{
		margin-top: -130px;
		margin-bottom: 300px;

		@include break(tablet){
			width: 100%;
			order: 1;
			margin-bottom: $p32;
			margin-top: -80px;
		}
	}
}

section.different-categories{
	background: $light-blue;
	padding: 0;
	position: relative;

	@include break(tablet){
		padding: 100px 0;
	}

	@include break(v-mobile){
		padding: $p40 0;
	}

	.container{
		@include break(desktop-only){ //mobile first
			width: 100%;
			max-width: 1440px;
			margin: 0 auto;
			padding: 0;
		}

		.content{
			display: flex;
			justify-content: space-between;
			// flex-wrap: wrap;

			@include break(tablet){
				display: block;
			}
		}
	}

	.title--box{
		flex: 1;
		padding: $p64 96px;

		@include break(tablet){
			display: block;
			padding: 0;
			margin-bottom: $p48;
		}

		h2{
			@include break(desktop-only){
				max-width: 360px;
			}

			@include break(tablet){
				text-align: center;
			}
		}
	}

	.info-boxes{
		width: 840px;
		display: flex;
		flex-wrap: wrap;
		border-right: 1px solid $white;

		@include break(tablet){
			width: auto;
			justify-content: space-between;
			border: none;
		}

		& .box-item:nth-last-child(2){
			@include break(tablet){
				border: none;
				margin: 0;
				padding-bottom: 0;
			}
		}

		.box-item{
			width: 50%;
			min-height: 400px;
			padding: $p64 $p32;
			border-left: 1px solid $white;
			border-top: 1px solid $white;
			position: relative;

			@include break(tablet){
				width: 100%;
				min-height: 0;
				padding: 0;
				border: none;
				display: flex;
				border-bottom: 1px solid $white;
				padding: $p32 0 $p64;
			}

			@include break(v-mobile){
				display: block;
				padding: $p32 0;
			}

			&:nth-of-type(1),
			&:nth-of-type(2){
				border-top: none;
			}

			&:last-of-type{
				@include break(tablet){
					border: none;
					padding: 0;
					margin: 0;
				}
			}

			&.phantom{
				@include break(tablet){
					display: none;
				}
			}

			h3{
				max-width: 200px;

				@include break(small-screen){
					margin-bottom: $p40;
					min-height: 90px; //equiv. 3 lines
				}

				@include break(tablet){
					width: 215px;
					min-height: 0;
					max-width: none;
					margin-right: 100px;
					margin-bottom: 0;
				}

				@include break(mobile){
					margin-right: 40px;
				}

				@include break(v-mobile){
					width: auto;
					margin-bottom: $p24;
				}
			}

			p{
				max-width: 310px;
				position: absolute;
				bottom: $p64;
				padding-right: $p32;

				@include break(tablet){
					position: static;
					padding: 0;
					max-width: none;
					flex: 1;
				}
			}
		}
	}
}

section.vote-public{
	padding: 223px 0 0;
	background: $white;
	position: relative;

	@include break(tablet){
		padding: 100px 0;
	}

	@include break(mobile){
		padding: $p128 0 0;
	}

	.container{
		.content{
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			@include break(tablet){
				display: block;
				position: relative;
			}
		}
	}

	.left-side-img-holder,
	.right-side-txt-holder{
		width: calc((100% - 32px) / 2);
		border-radius: 10px;

		@include break(tablet){
			width: 100%;
		}

		img{
			max-width: 100%;
			height: auto;
			display: block;
		}
	}

	.left-side-img-holder{
		margin-top: 160px;
		height: 530px;
		background-size: cover;
		background-position: center;

		@include break(tablet){
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: 0;
			width: 300px;
			height: 380px;
			transform: translateY(-50%);
		}

		@include break(mobile){
			// display: none;
			top: 0;
			right: auto;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 200px;

		}
	}

	.right-side-txt-holder{
		padding: 116px 92px 91px 50px;

		@include break(small-screen){
			padding: 90px 50px;
		}

		@include break(tablet){
			width: 80%;
			padding: 70px 180px 70px 40px;
		}

		@include break(mobile){
			width: 100%;
			padding: 140px $p40 $p64;

		}

		@include break(v-mobile){
			padding: $p24;
			padding-top: 140px;
		}

		h2{
			margin-bottom: $p40;

			@include break(mobile){
				text-align: center;
			}
		}

		.has--wysiwyg{
			p{
				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
	}

	.pastille{
		width: 200px;
		height: 200px;
		position: absolute;
		top: 116px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
		align-items: center;

		@include break(tablet){
			display: none;
		}

		.icon-holder{
			position: absolute;
			width: 54px;

			img{
				max-width: 100%;
				height: auto;
				display: block;
			}
		}

		.label-txt{
			width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			

			img{
				animation: irotate 45s infinite linear;
			}
		}
	}
}

// Animation txt label
@keyframes irotate {
	0% {
	  transform: rotate(0deg);
	}
  
	100% {
	  transform: rotate(360deg);
	}
  }

section.banner-cta-hesitant{
	text-align: center;
	padding: 128px 0;

	@include break(tablet){
		padding: $p80 0;
	}

	@include break(v-mobile){
		padding: $p40 0;
	}

	.text-box{
		max-width: 823px;
		margin: 0 auto;

		h2,
		p{
			margin-bottom: $p32;
		}
	}
}














