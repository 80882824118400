.single-participant-info{
	padding: 96px 0;

	@include break(tablet){
		padding: $p80 0;
	}
	
	@include break(v-mobile){
		padding: $p40 0;
	}

	.single-participant-content{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;

		//LEFT SIDE (details)
		.single-part-left{
			width: 400px;

			@include break(small-screen){
				width: 360px;
			}

			@include break(tablet){
				// width: 300px;
				width: 100%;
				display: flex;
				flex-wrap: wrap;
			}

			ul.single-participant-details{
				margin-bottom: $p40;

				@include break(tablet){
					width: 100%;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					order: 2;
				}

				li{
					@extend .h5;
					padding-bottom: $p24;
					margin-bottom: $p16;
					border-bottom: 1px solid rgba(0, 43, 61, .3);
					
					@include break(tablet){
						width: calc((100% - 30px) / 2);
						margin-bottom: 30px;
					}

					@include break(mobile){
						width: 100%;
					}
				}
			}

			.social-media-share{

				@include break(tablet){
					width: 100%;
					order: 1;
					margin-bottom: $p40;
					justify-content: flex-end;
				}

				li{

					@include break(tablet){
						margin-right: 0;
						margin-left: $p16;
					}
				}
			}
		}

		//RIGHT SIDE (description)
		.single-part-right{
			flex: 1;
			padding-left: $p32;

			@include break(tablet){
				padding-left: 0;
			}

			.description{
				margin-bottom: $p48;
				
				p{
					margin-bottom: $p24;
				}
			}
		}
	}
}

.single-similar-products{
	background: $white;
	padding: $p40;

	@include break(mobile){
		padding: $p32;
	}

	@include break(v-mobile){
		padding: $p24 $p16;
	}

	.container-full{
		width: 100%;
		max-width: 1740px;
		margin-left: auto;
		margin-right: auto;
	}

	.section--title{
		margin-bottom: $p80;

		@include break(tablet){
				margin-bottom: $p64;
			}

			@include break(v-mobile){
				margin-bottom: $p32;
			}
	}

	// .slider-holder{
	// 	.slide--item{
	// 		padding: 0px $p32;

	// 		@include break(tablet){
	// 			padding: 0px $p24;
	// 		}

	// 		@include break(v-mobile){
	// 			padding: 0px;
	// 		}
	// 	}

	// 	.product-categories li:first-of-type{
	// 		display: none;
	// 	}

	// 	.slick-arrow{
	// 		display: none !important;
	// 	}

	// 	.slick-list{
	// 		padding: 0 150px !important;

	// 		@media only screen and (max-width: 1400px){
	// 			padding: 0 !important;
	// 		}

	// 		&:before,
	// 		&:after{
	// 			content: '';
	// 			width: 150px;
	// 			height: 100%;
	// 			background: rgba(255, 255, 255, 0.3);
	// 			background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 30%, rgba(255,255,255,0.3) 100%);
	// 			position: absolute;
	// 			z-index: 1;
	// 			top: 0;
	// 			left: 0;

	// 			@media only screen and (max-width: 1400px){
	// 				content: none;
	// 			}
	// 		}

	// 		&:after{
	// 			left: auto;
	// 			right: 0;
	// 			background: rgba(255, 255, 255, 0.3);
	// 			background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 30%, rgba(255,255,255,0.3) 100%);
	// 		}
	// 	}
		
	// 	.card{ //general.scss

	// 	}
	// }
}


	.slider-holder{
		.slide--item{
			padding: 0px $p32;

			@include break(tablet){
				padding: 0px $p24;
			}

			@include break(v-mobile){
				padding: 0px;
			}
		}

		// .product-categories li:first-of-type{
		// 	display: none;
		// }

		.slick-arrow{
			display: none !important;
		}

		.slick-list{
			padding: 0 150px !important;

			@media only screen and (max-width: 1400px){
				padding: 0 !important;
			}

			&:before,
			&:after{
				content: '';
				width: 150px;
				height: 100%;
				background: rgba(255, 255, 255, 0.3);
				background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 30%, rgba(255,255,255,0.3) 100%);
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;

				@media only screen and (max-width: 1400px){
					content: none;
				}
			}

			&:after{
				left: auto;
				right: 0;
				background: rgba(255, 255, 255, 0.3);
				background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 30%, rgba(255,255,255,0.3) 100%);
			}
		}
		
		.card{ //general.scss

		}
	}


.fancybox__backdrop{
	background: rgba(0, 43, 61, 0.9);
}

.single-media{

	.container-special{
		margin-top: -100px;
	}

	.slide--item{
		padding: 0 $p16;

		.play {
			@include absolute-center(center);
			transform: translate(-50%, -50%) scale(0.9);
			opacity: 1;
			transition: all 0.4s;
			top: 50%;
			left: 50%;
			// transform: translate(-50%, -50%);
		}

		.card{
			height: 500px;
			border-radius: 10px;
			background-repeat: no-repeat;
			background-size: cover;

			@include break(tablet){
				height: 400px;
			}

			@include break(mobile){
				height: 250px;
			}

			@include break(v-mobile){
				height: 200px;
			}

			&:before {
				content: " ";
				@include box-shadow;
				opacity: 0;
			}

			// .play {
			// 	@include absolute-center(center);
			// 	transform: translate(-50%, -50%) scale(0.9);
			// 	opacity: 1;
			// 	transition: all 0.4s;
			// }

			&:hover{
				:before {
					opacity: 1;
					transform: translateY(0px);
				}
				.play {
					opacity: 1;
					transform: translate(-50%, -50%) scale(1);
				}
			}


		} //.card

		a{
			cursor: pointer;
		}
	}

	.slick-list{
		// padding: 0 150px !important;

		@media only screen and (max-width: 1400px){
			//padding: 0 !important;
		}

		&:before,
		&:after{
			content: '';
			width: 280px;
			height: 100%;
			// background: rgb(255,255,255);
			// background: linear-gradient(90deg, rgba(255,255,255,1) 0%, 
			// 	rgba(255,255,255,0.4500175070028011) 20%, 
			// 	rgba(255,255,255,0.4500175070028011) 100%);
			background: rgba(255, 255, 255, 0.3);
			background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 10%, rgba(255,255,255,0.3) 100%);
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;

			@media only screen and (max-width: 1400px){
				width: 150px;
			}

			@include break(tablet){
				width: 70px;
			}

			@include break(v-mobile){
				width: 30px;
			}
		}

		&:after{
			left: auto;
			right: 0;
			background: rgba(255, 255, 255, 0.3);
			background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 10%, rgba(255,255,255,0.3) 100%);
		}
	}

	.slick-dots{
		padding-top: $p40 !important;

		@include break(mobile){
			padding-top: $p32;
		}

		@include break(v-mobile){
			width: 80%;
			max-width: 200px;
			margin: auto;
			padding-top: $p8;
		}
	}
}

//SLICK GLOBAL CSS
.default-dots{
	.slick-dots{
		padding-top: $p64;
		display: flex;
		justify-content: center;

		@include break(mobile){
			padding-top: $p32;
		}

		@include break(v-mobile){
			width: 80%;
			max-width: 200px;
			margin: auto;
			padding-top: $p8;
		}

		li{
			margin: 0 3px;

			@include break(v-mobile){
				flex: 1;
			}

			button{
				width: 70px;
				height: 4px;
				background: $light-blue;
				transition: .25s;
				border: none;
				border-radius: 4px;
				font-size: 0;

				@include break(v-mobile){
					width: 100%;
				}
			}

			&.slick-active{
				button{
					background: $dark-blue;
				}
			}
		}
	}
}

ul.heateor_sss_sharing_ul li.heateorSssSharingRound{
	margin-right: 10px !important;
}

.hide-me{
	display: none;
}





