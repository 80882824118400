header {
	background-color: $white;
	width: 100%;

	.container {
		display: flex;
		justify-content: space-between;
		align-items:center;
		padding-top: 10px;
		padding-bottom: 10px;
		@include break(small-screen) {
			padding-top: 22px;
			padding-bottom: 22px;
		}
	}

	.logo {
		width: 135px;
		height: auto;
		@include break(tablet) {
			width: 105px;
		}
	}

	.nav {
		@include break(small-screen) {
			// display: none;
			opacity: 0;
			visibility: hidden;
			z-index: -10;
			
		}
		ul {
			display: flex;
			justify-content: space-between;

			li {
				margin-left: 50px;
				a {
					@include btn;
					&:hover {
						border-color: transparent;
						background-color: $light-blue;
						color: $dark-blue;
					}
				}
				&:last-of-type a {
					background-color: $blue;
					color: $white;
					margin-left: $p24;
					&:hover {
						border-color: $dark-blue;
						background-color: transparent;
						color: $dark-blue;
					}
				}
			}
		}
	}

	.nav_mobile {
		display: none;
		position: relative;
		cursor: pointer;
		@include break(small-screen) {
			display: block;
		}
		.burger {
			height: 26px;
			width: 30px;
			display: flex;
			justify-content: space-around;
			flex-direction: column;
			div {
				background-color: $blue;
				width: 100%;
				height: 2px;
			}
		}
		.close {
			opacity: 0;
			position: absolute;
			top: -10px;
			left: 7px;
			font-size: 35px;
			line-height: 41px;
		}
	}
}


//SIMPLE RESPONSIVE HEADER

.close-nav_mobile{
	background: rgba(0, 43, 61, 0.32);
	border-radius: 50%;
	width: 50px;
	height: 50px;
	display: none;
	position: absolute;
	top: 32px;
	right: 32px;

	&:before,
	&:after{
		content: '';
		background: $white;
		width: 60%;
		height: 5px;
		transform-origin: center;
		position: absolute;
		border-radius: 4px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after{
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	@include break(small-screen){
		display: block;
	}
}

body{
	.nav{
		@include break(small-screen) {
			background: #009FE3;
			opacity: .4;
			height: 100vh;
			width: 100%;
			position: fixed;
			top: 0;
			bottom: 0;
			right: 100%;
			// overflow-y: scroll;
			transition: .25s;


		}

		div.menu-primary-navigation-container{
			@include break(small-screen){
				display: flex;
				height: 100%;
				width: 100%;
				justify-content: center;
				align-items: center;
				text-align: center;
			}

			ul.menu{
				
				@include break(small-screen){
					display: block;
				}

				li{
					@include break(small-screen){
						margin-left: 0;
						margin-bottom: 50px;
					}

					&:last-of-type{
						a{
							@include break(small-screen){
								background: white;
								color: $blue;
							}
						}
					}

					a{
						@include break(small-screen){
							margin-left: 0;
							color: $white;
							font-size: 4vw;
							border-radius: 200px;
						}
					}
				}
			}
		}
	}
}

body.nav-is-open{
	header{
		.nav{
			@include break(small-screen) {
				right: 0;
				opacity: 1;
				visibility: visible;
				z-index: 1000;
			}

			div.menu-primary-navigation-container{
				@include break(small-screen){
				}

				ul.menu{
					@include break(small-screen){
						display: block;
					}
				}
			}
		}
	}
}






